<template>
  <i>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 2C10.448 2 10 2.448 10 3V11H6L12 17L18 11H14V3C14 2.448 13.552 2 13 2H11ZM2 20V22H22V20H2Z" fill="black"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'DownloadIcon'
}
</script>
