<template>
  <i>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5003 4.16667C10.2206 4.16667 8.33366 6.05364 8.33366 8.33334V18.75C6.03158 18.75 4.16699 20.6146 4.16699 22.9167V33.3333C4.16699 35.6354 6.03158 37.5 8.33366 37.5V41.6667C8.33366 43.9464 10.2206 45.8333 12.5003 45.8333H37.5003C39.78 45.8333 41.667 43.9464 41.667 41.6667V14.5833L31.2503 4.16667H12.5003ZM12.5003 8.33334H29.167V16.6667H37.5003V18.75H12.5003V8.33334ZM8.33366 22.9167H11.4587C13.1837 22.9167 14.5837 24.3167 14.5837 26.0417C14.5837 27.7667 13.1837 29.1667 11.4587 29.1667H10.417V33.3333H8.33366V22.9167ZM27.0837 22.9167H33.3337V25H29.167V27.0833H32.4995V29.1667H29.167V33.3333H27.0837V22.9167ZM16.667 22.9655H20.0605C23.3564 22.9655 25.0003 24.9477 25.0003 27.3519V28.9592C25.0003 31.3925 23.3529 33.3333 20.0321 33.3333H16.667V22.9655ZM10.417 25V27.0833H11.4587C12.0337 27.0833 12.5003 26.6167 12.5003 26.0417C12.5003 25.4667 12.0337 25 11.4587 25H10.417ZM18.7503 25.0488V31.25H20.0321C21.3487 31.25 22.917 30.8508 22.917 28.9592V27.3519C22.917 25.4477 21.3647 25.0488 20.0605 25.0488H18.7503ZM12.5003 37.5H37.5003V41.6667H12.5003V37.5Z" fill="black"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'PdfIcon'
}
</script>
