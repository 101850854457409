<template>
  <main class="main">
    <section class="welcome welcome__reports">
      <div class="container">
        <div class="welcome__inner">
          <h1 class="welcome__title welcome__reports__title">
            ОТЧЕТЫ
          </h1>
        </div>
      </div>
    </section>

    <section class="section container">
      <div v-if="reportsData && reportsData.length" class="form__container reports__container">
        <div class="reports">
          <div v-for="(item, index) in reportsData" :key="index" class="reports__item">
            <div class="reports__item__icon">
              <PdfIcon/>
            </div>
            <div class="reports__item__date">
              {{  parseDate(item.date) }}
            </div>
            <a v-if="item.url" :href="item.url" class="reports__item__link" target="_blank">
              <DownloadIcon/>
            </a>
            <a v-else-if="item.file" download="true" :href="url + item.file"  class="reports__item__link" target="_blank">
              <DownloadIcon/>
            </a>
          </div>
        </div>
      </div>

      <div v-else class="form__container reports__notfound">
        Отчетов нет
      </div>
    </section>
  </main>
</template>

<script>
import PdfIcon from 'components/svg/Pdf.vue'
import DownloadIcon from 'components/svg/Download.vue'

export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_REPORTS_PAGE')
  },
  name: 'reports',
  computed: {
    reportsData () {
      return this.$store.state.reports_page?.reports
    },
    url: function () {
      return this.$store.state.api
    },
    nav () {
      return this.$store.state.navigators.find(item => item.link === this.$route.path)
    }
  },
  methods: {
    parseDate (date) {
      const newDate = new Date(date)
      return this.monthsArray[newDate.getMonth()] + ' ' + newDate.getFullYear()
    }
  },
  data () {
    return {
      monthsArray: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ]
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      this.nav.meta_title,
      this.nav.meta_keywords,
      this.nav.meta_description,
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    PdfIcon,
    DownloadIcon
  }
}
</script>
